import type { FC } from 'react';
import { ReactComponent as DTHLogo } from '../assets/images/DTH_Logo_watermark.svg';

const IntroductionBanner: FC = () => {
  return (
    <div className="flex-grow relative overflow-hidden text-white bg-infoBlue p-30px flex justify-center items-center">
      <div className="absolute md:top-[37%] sm:top-1/2 sm:right-8 md:left-0 sm:-translate-y-1/2 mix-blend-multiply">
        <DTHLogo className="md:pt-[25vh] md:h-[99vh] md:w-[25vw] h-[569px] w-[786px]" /></div>
      <div
        className="sm:space-y-[125px] sm:absolute sm:top-[0px] sm:mt-[298px] sm:-translate-y-1/3 sm:px-4
             space-y-[125px] mt-[8vh] rounded-[8px] md:mt-[265px] lg:top-1/3 lg:mt-[8vh] xl:text-center"
      >
        <div className="space-y-4">
          <h3 className="text-[25px] xl:text-[40px] leading-50px text-justify font-bold font-jnjDBold xl:text-center">
            Welcome to DTH!
          </h3>
          <p className="text-base/4 font-normal font-jnjTLight xl:text-justify xl:w-[325px] leading-normal lg:leading-none">
            The objective of the Digital Transformation Hub (DTH) initiative is to create a user friendly online
            platform specifically designed for Research and Development (R&D) Quality management. This portal aims to
            provide a centralized and accessible solution for R&D professionals, teams and organizations.
          </p>
        </div>
        <p className="hidden sm:block font-jnjTLight xl:text-center">Please select a tool to proceed.</p>
      </div>
    </div>
  );
};

export default IntroductionBanner;
